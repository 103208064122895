const colisStat = {
  pd_transfer: "pd_transfer",
  pd_dispatch: "pd_dispatch",
  pd_receive: "pd_receive",
  pickup_prog: "pickup_prog",
  pickup_fail: "pickup_fail",
  pickup: "pickup",
  in_rs: "in_rs",
  progress: "progress",
  progress_success: "progress_success",
  progress_fail: "progress_fail",
  progress_reported: "progress_reported",
  completed: "completed",
  closed: "closed",
  to_return: "to_return",
  return_progress: "return_progress",
  transfer_zone_progress: "transfer_zone_progress",
  completed_non_retourne: "completed_non_retourne",
};
/*
1-pending to transfer (to add to movable from exped)
2-pending to dispatch (added to movable unit and waiting to dispatch)
3-pending to pickup (movable unit dipatched and waiting to pickup from livrime)
4-pending to receive (movable unit in progess to pick that package)
5-pickup
6-in Runsheet ( but the rs not dispatched yet)
7-in progross ( delivery in progress )
8-in progress success ( delivery success )
9-in progress failure (delivary failed or to reschedule)
10-completed ( delivary completed and waiting driver to pay livrime)
11-closed ( package payed from livrime to client and done or fail after 3 attempt and returned)
12-to return after 3 attempts

const colisStat = {
    pd_transfer: 'pd_transfer',
    pd_dispatch: 'pd_dispatch',
    pd_receive: 'pd_receive',
    pickup_prog: 'pickup_prog',
    pickup_fail: 'pickup_fail',
    pickup: 'pickup',
    in_rs: 'in_rs',
    progress: 'progress',
    progress_success: 'progress_success',
    progress_fail: 'progress_fail',
    progress_reported: 'progress_reported',
    completed: 'completed',
    closed: 'closed',
    to_return: 'to_return',
    return_progress:'return_progress'
}

*/

exports.stat = colisStat;

exports.ColisStatus = {
  "ATT-ENVOIE": "ATT-ENVOIE",
  "ATT-DISP-ENVOIE": "ATT-DISP-ENVOIE",
  "ATT-ENLEV": "ATT-ENLEV",
  "ENLEV-COURS": "ENLEV-COURS",
  "ENLEV-ECHEC": "ENLEV-ECHEC",
  "ENLEV-SUCC": "ENLEV-SUCC",
  "ATT-LIV": "ATT-LIV",
  "LIV-PLAN": "LIV-PLAN",
  "LIV-COURS": "LIV-COURS",
  "LIV-COURS-SUCC": "LIV-COURS-SUCC",
  "LIV-COURS-ECHEC": "LIV-COURS-ECHEC",
  "LIV-COURS-REPO": "LIV-COURS-REPO",
  LIVRE: "LIVRE",
  ANL: "ANL",
  ENDOM: "ENDOM",
  RECUPERER: "RECUPERER",
  "ATT-RECUP": "ATT-RECUP",
  FACTURE: "FACTURE",
  "ATT-TRANSF": "ATT-TRANSF",
  "TRANSF-PLAN": "TRANSF-PLAN",
  "TRANSF-COURS": "TRANSF-COURS",
};

exports.colisStatusLabels = {
  "ATT-ENVOIE": "En-attente d'envoie",
  "ATT-DISP-ENVOIE": "En attent dispatch d'envoie",
  "ATT-ENLEV": "En-attente d'enlévement",
  "ENLEV-COURS": "Enlévement en cours",
  "ENLEV-ECHEC": "Echec enlévement",
  "ENLEV-SUCC": "Enlévement success",
  "ATT-LIV": "En-attente livraison",
  "LIV-PLAN": "Livraison planifié",
  "LIV-COURS": "Livraison en cours",
  "LIV-COURS-SUCC": "Livraison en cours livré",
  "LIV-COURS-ECHEC": "Livraison en cours annulé",
  "LIV-COURS-REPO": "Livraison en cours reporté",
  LIVRE: "Livré",
  ANL: "Annulé",
  ENDOM: "Endommagé",
  RECUPERER: "Recupéré",
  "ATT-RECUP": "En-attente Récuperation",
  FACTURE: "Facturé",
  "ATT-TRANSF": "En-attente Transfert",
  "TRANSF-PLAN": "Transfert planifié",
  "TRANSF-COURS": "Transfert en cours",
};

exports.ColisStatusOptions = [
  { value: "ATT-ENVOIE", label: "En-attente d'envoie" },
  { value: "ATT-DISP-ENVOIE", label: "En attent dispatch d'envoie" },
  { value: "ATT-ENLEV", label: "En-attente d'enlévement" },
  { value: "ENLEV-COURS", label: "Enlévement en cours" },
  { value: "ENLEV-ECHEC", label: "Echec enlévement" },
  { value: "ENLEV-SUCC", label: "Enlévement success" },
  { value: "ATT-LIV", label: "En-attente livraison" },
  { value: "LIV-PLAN", label: "Livraison planifié" },
  { value: "LIV-COURS", label: "Livraison en cours" },
  { value: "LIV-COURS-SUCC", label: "Livraison en cours livré" },
  { value: "LIV-COURS-ECHEC", label: "Livraison en cours annulé" },
  { value: "LIV-COURS-REPO", label: "Livraison en cours reporté" },
  { value: "LIVRE", label: "Livré" },
  { value: "ANL", label: "Annulé" },
  { value: "ENDOM", label: "Endommagé" },
  { value: "RECUPERER", label: "Recupéré" },
  { value: "ATT-RECUP", label: "En-attente Récuperation" },
  { value: "FACTURE", label: "Facturé" },
  { value: "ATT-TRANSF", label: "En-attente Transfert" },
  { value: "TRANSF-PLAN", label: "Transfert planifié" },
  { value: "TRANSF-COURS", label: "Transfert en cours" },
];

exports.ColisStatusBg = {
  "ATT-ENVOIE": "bg-warning text-dark",
  "ATT-DISP-ENVOIE": "bg-warning text-dark",
  "ATT-ENLEV": "bg-warning text-dark",
  "ENLEV-COURS": "bg-info",
  "ENLEV-ECHEC": "bg-danger",
  "ENLEV-SUCC": "bg-success",
  "ATT-LIV": "bg-warning text-dark",
  "LIV-PLAN": "bg-info",
  "LIV-COURS": "bg-info",
  "LIV-COURS-SUCC": "bg-success",
  "LIV-COURS-ECHEC": "bg-danger",
  "LIV-COURS-REPO": "bg-warning text-dark",
  LIVRE: "bg-success",
  ANL: "bg-danger",
  ENDOM: "bg-danger",
  RECUPERER: "bg-success",
  "ATT-RECUP": "bg-warning text-dark",
  FACTURE: "bg-success",
  "ATT-TRANSF": "bg-warning text-dark",
  "TRANSF-PLAN": "bg-info",
  "TRANSF-COURS": "bg-info",
};

exports.ColisAfexStatus = {
  "pre_manifest": "pre_manifest",
  "awaiting_removal": "awaiting_removal",
  "being_removed": "being_removed",
  "awaiting_recovered": "awaiting_recovered",
  "awaiting_delivery": "awaiting_delivery",
  "awaiting_return": "awaiting_return",
  "awaiting_transfert": "awaiting_transfert",
  "awaiting_rto": "awaiting_rto",
  "in_delivery": "in_delivery",
  "being_recovered": "being_recovered",
  "being_returned": "being_returned",
  "in_transfert": "in_transfert",
  "in_rto": "in_rto",
  "delivered": "delivered",
  "returned": "returned",
  "recovered": "recovered",
  "transfered": "transfered",
  "pending": "pending",
  "canceled": "canceled",
  "not_recovered": "not_recovered",
  "anomaly": "anomaly",
  "exception_refunded": "exception_refunded",
  "exception_not_refunded": "exception_not_refunded",
  "billed": "billed",
};

exports.ColisAfexStatusBg = {
  "pre_manifest": "bg-warning text-dark",
  "awaiting_removal": "bg-warning text-dark",
  "being_removed": "bg-warning text-dark",
  "awaiting_recovered": "bg-warning text-dark",
  "awaiting_delivery": "bg-info",
  "awaiting_return": "bg-danger",
  "awaiting_transfert": "bg-info",
  "awaiting_rto": "bg-info",
  "in_delivery": "bg-info",
  "being_recovered": "bg-success",
  "being_returned": "bg-warning text-dark",
  "in_transfert": "bg-info",
  "in_rto": "bg-info",
  "delivered": "bg-success",
  "returned": "bg-danger",
  "recovered": "bg-warning text-dark",
  "transfered": "bg-success",
  "pending": "bg-warning text-dark",
  "canceled": "bg-danger",
  "not_recovered": "bg-danger",
  "anomaly": "bg-danger",
  "exception_refunded": "bg-success",
  "exception_not_refunded": "bg-danger",
  "billed": "bg-success",
};

exports.ColisAfexStatusLabels = {
  "pre_manifest": "Pré-manifest",
  "awaiting_removal": "En attente d'enlèvement",
  "being_removed": "En cours d'enlèvement",
  "awaiting_recovered": "En attente de récupération",
  "awaiting_delivery": "En attente de livraison",
  "awaiting_return": "En attente de retour",
  "awaiting_transfert": "En attente de transfert",
  "awaiting_rto": "En attente RTO",
  "in_delivery": "En cours de livraison",
  "being_recovered": "En cours de récupération",
  "being_returned": "En cours de retour",
  "in_transfert": "En cours de transfert",
  "in_rto": "En cours RTO",
  "delivered": "Livré",
  "returned": "Retourné",
  "recovered": "Récupéré",
  "transfered": "Transféré",
  "pending": "En attente",
  "canceled": "Annulé",
  "not_recovered": "Non récupéré",
  "anomaly": "Anomalie",
  "exception_refunded": "Exception remboursé",
  "exception_not_refunded": "Exception non remboursé",
  "billed": "Facturé",
};

exports.ColisTypeEnvoieOptions = [
  { value: "livraison", label: "Livraison" },
  { value: "echange", label: "Échange" },
  { value: "recuperation", label: "Récupération" },
]

exports.ColisTypeEnvoieLabels = {
  "livraison": "Livraison",
  "echange": "Échange",
  "recuperation": "Récupération",
}

exports.ColisTypeEnvoie = {
  "livraison": "livraison",
  "echange": "echange",
  "recuperation": "recuperation",
}
